<template>
  <div>
    <div class="r_header">
      <div class="r_star flex-container">
        <h2>综合评价：</h2>
        <van-rate
          readonly
          class="flex1"
          v-model="startValue"
          void-icon="star"
          void-color="#E3E3E3"
          color="#FF4848"
        />
        <span>{{ (startValue / 5) * 100 }}% 满意</span>
      </div>
      <ul class="r_screen flex-container">
        <li
          @click="screenIndex = index"
          :class="{ act: index == screenIndex }"
          v-for="(item, index) in screenList"
          :key="index"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="r_reviewUl">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <template v-for="(item, index) in context">
          <div class="r_reviewList" :key="index">
            <oReview isgood iscontext :review="item"></oReview>
          </div>
        </template>
      </van-list>
    </div>
  </div>
</template>

<script>
import oReview from "../goods/components/reviewComponents";
import "vant/lib/rate/style";
import "vant/lib/list/style";
import { post } from "@get/http";
import { Rate, List } from "vant";
export default {
  props: {},
  data() {
    return {
      loading: false,
      finished: false,
      startValue: 3,
      screenIndex: 0,
      context: [],
      page: 1,
      screenList: [
        "全部",
        "只看有图",
        "只看文字",
        "按点赞数倒序",
        "按点赞数正序",
      ],
    };
  },
  watch: {
    screenIndex() {
      if (
        this.screenIndex == 0 ||
        this.screenIndex == 1 ||
        this.screenIndex == 2
      ) {
        this.getReviewData(this.screenIndex);
      } else {
        this.getReviewData(0, this.screenIndex == 3 ? 1 : 2);
      }
    },
  },
  components: {
    oReview,
    vanRate: Rate,
    vanList: List,
  },
  created() {
    // this.getReviewData();
  },
  methods: {
    getReviewData(type = 0, order = 0) {
      this.context = [];
      this.page = 1;
      this.onLoad(type, order);
    },
    onLoad(type = 0, order = 0) {
      post("/Memberevaluate/show", {
        data: {
          commonid: this.$route.query.c_id, //商品commonid
          type, //默认0)显示全部 1)只看有图 2)只看文字
          state: 1, //1)评论 2)买家秀 其他)10001错误报异常
          order, //默认0)ID倒序 1)点赞倒序 2)点赞正序
          page: this.page,
          pageSize: 20,
        },
      }).then((res) => {
        // 加载状态结束
        this.page++;
        this.loading = false;
        this.context.push(...res.data);
        this.startValue = res.avg_scores;
        if (res.data.length < 20) {
          this.finished = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.r_reviewUl {
  padding-bottom: rem(50);
}
.r_header {
  padding: 0 rem(45);
  box-sizing: border-box;
  background: #fff;
}
.r_star {
  align-items: center;
  padding: rem(30) 0;
  border-bottom: 1px solid #efefef;
  h2 {
    color: #333;
    font-size: rem(26);
    font-weight: bold;
  }
  span {
    color: #999;
    font-size: rem(24);
  }
}

.r_screen {
  flex-wrap: wrap;
  padding: rem(30) 0 rem(10) 0;
  li {
    margin-right: rem(20);
    margin-bottom: rem(20);
    background: #f5f5f4;
    border-radius: rem(27);
    height: rem(54);
    padding: 0 rem(20);
    color: #333333;
    text-align: center;
    font-size: rem(26);
    line-height: rem(54);
  }
  .act {
    background: #fff;
    color: #ff4848;
    position: relative;
    text-align: center;
  }
  .act::before {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 99%;
    height: 99%;
    border-radius: rem(27);
    border: rem(2) solid #ff4848;
    box-sizing: border-box;
  }
}

.r_reviewList {
  margin: rem(30) auto;
  border-radius: rem(16);
  width: rem(710);
  background: #fff;
  padding: rem(30) rem(24);
  box-sizing: border-box;
  /deep/ {
    .r_context,
    .r_showContext,
    .r_ul {
      margin-left: rem(78);
    }
  }
}
/deep/ .van-rate__item {
  padding: 0;
}
</style>
